
import {defineComponent, ref} from "vue";
import {StrapiService} from "@/Services/StrapiService";
import imageCompressor from 'vue-image-compressor'
import Toast from "@/components/App/Toast.vue";

export default defineComponent({
  name: 'ImageUpload',
  components: {Toast, imageCompressor},
  setup() {
    const imageTitle = ref<string>('');
    const fileInput = ref<HTMLInputElement | null>(null)
    const uploadFile = ref<Blob | null>(null)
    const quality = ref<number>(50)
    const showToast = ref<boolean>(false)
    const toastType = ref<'info'|'error'>('info')
    const toastMessage = ref<string>('')
    const loading = ref<boolean>(false)

    const uploadImage = async () => {
      loading.value = true
      if (imageTitle.value !== '' && uploadFile.value !== null) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await StrapiService.uploadImage({title: imageTitle.value, file: uploadFile.value}).finally(() => {
          setTimeout(() => {
            toastMessage.value = 'Upload erfolgreich!'
            showToast.value = true
          }, 2500)
          toastMessage.value = ''
          showToast.value = false
          imageTitle.value = ''
          uploadFile.value = null
          quality.value = 50
          loading.value = false
        })
      } else {
        toastMessage.value = 'Bitte einen Titel eingeben und eine Bild auswählen.'
        showToast.value = true
      }
    }

    const getFiles = async (obj: any) => {
      const base64Response = await fetch(`${obj.compressed.base64}`);
      uploadFile.value = await base64Response.blob();

      quality.value = 49
    }

    return {loading, imageTitle, fileInput, uploadFile, quality, showToast, toastType, toastMessage, uploadImage, getFiles}
  }
})
